import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Theme = props => {
    useEffect(() => {
        function setAccentColor(accentKey) {
            let accentColor;
            switch (accentKey) {
                case 'git':
                    accentColor = '#f05133';
                    break;
                case 'javascript':
                    accentColor = '#ffc400';
                    break;
                case 'react':
                    accentColor = '#61dafb';
                    break;
                case 'home':
                    accentColor = '#64dd17';
                    break;
                default:
                    accentColor = '#2962ff';
            }
            const body = document.body;
            if (body) body.style.setProperty('--accent-color', accentColor);
        }
        if (props.accentKeyProp) {
            setAccentColor(props[props.accentKeyProp]);
        } else if (props.accentKey) {
            setAccentColor(props.accentKey);
        } else {
            setAccentColor();
        }
    }, [props]);

    return <React.Fragment>{props.children}</React.Fragment>;
};

Theme.propTypes = {
    accentKeyProp: PropTypes.string,
    accentKey: PropTypes.string,
};

export default Theme;
