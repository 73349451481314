import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './FeatureCenter.module.scss';

export const TITLE_LEVEL = {
    H2: 'h2',
    H3: 'h3',
};
const FeatureCenter = ({
    svgName,
    title = '',
    description = '',
    children,
    titleLevel = TITLE_LEVEL.H2,
    illustrationClassName,
}) => {
    return (
        <article>
            <div className={styles.container}>
                {
                    {
                        [TITLE_LEVEL.H2]: (
                            <h2 className={styles.title}>{title.join('\n')}</h2>
                        ),
                        [TITLE_LEVEL.H3]: (
                            <h3 className={styles.title}>{title.join('\n')}</h3>
                        ),
                    }[titleLevel]
                }
                {description && <p>{description}</p>}
                {svgName && (
                    <div
                        className={cx(
                            styles.illustration,
                            illustrationClassName
                        )}
                    >
                        <SvgComponent name={svgName} className={styles.svg} />
                    </div>
                )}
                {children}
            </div>
        </article>
    );
};

FeatureCenter.propTypes = {
    svgName: PropTypes.string,
    title: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    children: PropTypes.node,
};

export default FeatureCenter;
