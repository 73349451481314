import React from 'react';
import styles from './CustomVideoPlayer.module.scss';

// autoplay
// Comme il y a plusieurs vidéos sur la page il faut autopause=0
// Chrome autoplay: il faut ajouter muted=1
// iOS autoplay: il faut playsinline=1

// src={`https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1&loop=1&title=0&byline=0&portrait=0&playsinline=1&muted=1&autopause=0`}

const CustomVideoPlayer = ({ vimeoVideoId, title }) => {
    return (
        <div className={styles.container}>
            <iframe
                sandbox
                src={`https://player.vimeo.com/video/${vimeoVideoId}?autoplay=0&loop=1&title=0&byline=0&portrait=0&playsinline=1&muted=1&autopause=0`}
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    border: 0,
                }}
                allow="autoplay; fullscreen"
                allowFullScreen
                title={title}
            ></iframe>
        </div>
    );
};

export default CustomVideoPlayer;
