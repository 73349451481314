import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgComponent from '../../assets/svg/SvgComponent';
import CheckMiniCard from '../CheckMiniCard/CheckMiniCard';
import Button from '../Button/Button';
import styles from './CourseBanner.module.scss';

const CourseBanner = ({
    title = [],
    description = '',
    svgName = 'ledevwebmoderne',
    content = [],
    forceColor,
    btnTheme = Button.Theme.GREEN,
    btnLinkUrl = '/',
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.illustration}>
                <SvgComponent
                    name={svgName}
                    className={cx(forceColor, styles.svg)}
                />
            </div>
            <h2 className={styles.title}>{title.join('\n')}</h2>

            <p className={styles.description}>
                {description.split('\n').map((desc, index) => (
                    <span key={index}>{desc}</span>
                ))}
            </p>
            <div className={styles.contentContainer}>
                {content.map(title => (
                    <CheckMiniCard
                        key={title}
                        forceColor={forceColor || 'forceYellow'}
                        title={title}
                    />
                ))}
            </div>
            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_BUTTON}
                theme={btnTheme}
                to={btnLinkUrl}
                className={styles.btn}
            >
                Découvre les capsules Git
            </Button.Component>
        </div>
    );
};

CourseBanner.propTypes = {
    title: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.string),
    forceColor: PropTypes.string,
    btnTheme: PropTypes.string,
    btnLinkUrl: PropTypes.string,
};

export default CourseBanner;
