import React from 'react';
import cx from 'classnames';
import FeatureCenter, { TITLE_LEVEL } from '../FeatureCenter/FeatureCenter';
import FeatureRow, {
    TITLE_LEVEL as FEATURE_ROW_TITLE_LEVEL,
} from '../FeatureRow/FeatureRow';
import CourseBanner from '../CourseBanner/CourseBanner';
import MainFeatures from '../MainFeatures/MainFeatures';
import Button from '../Button/Button';
import Container from '../Container/Container';
import CustomVideoPlayer from '../CustomVideoPlayer/CustomVideoPlayer';
import styles from './Concept.module.scss';

const Concept = () => {
    return (
        <div className="home">
            <section className={cx(styles.section, styles.sectionHero)}>
                <Container className={styles.hero}>
                    <h1 className={styles.heroTitle}>
                        Plus efficace que les screencasts découvre nos capsules
                        de formation
                    </h1>
                    <p>
                        <span role="img" aria-label="drapeau français">
                            🇫🇷
                        </span>{' '}
                        Exclusivité française{' '}
                        <span role="img" aria-label="drapeau français">
                            🇫🇷
                        </span>
                    </p>
                    <div className={styles.heroVideoPlayer}>
                        <CustomVideoPlayer
                            vimeoVideoId="480236894"
                            title="Présentation de la plateforme ledevwebmoderne"
                        />
                    </div>
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionWhite)}>
                <h2 className={styles.sectionTitle}>
                    Le constat{' '}
                    <span role="img" aria-label="visasge effrayé">
                        😱
                    </span>
                </h2>
                <Container>
                    <FeatureCenter
                        svgName="lostTime"
                        illustrationClassName={styles.constatIllustration}
                        title={[
                            'Les formations vidéos, blogs, livres blancs…',
                            'te font perdre beaucoup de temps et d’énergie',
                        ]}
                        titleLevel={TITLE_LEVEL.H3}
                        description={`Tu n’es pas le seul dans ce cas, nous aussi nous sommes passés par là`}
                    />
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionApproche)}>
                <div className={styles.page}>
                    <h2 className={styles.sectionTitle}>
                        Notre approche{' '}
                        <span role="img" aria-label="licorne">
                            🦄
                        </span>
                    </h2>
                    <p className={styles.subtitle}>
                        Privilégier <strong>la formation</strong>
                        <br /> à l’effet waouh et au référencement SEO
                    </p>

                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_RIGHT}
                        svgName="splitIntoCapsules"
                        title={
                            <React.Fragment>
                                Pour cela nos formations
                                <br /> sont découpées en modules
                                <br /> puis en <strong>capsules</strong>
                            </React.Fragment>
                        }
                        titleLevel={FEATURE_ROW_TITLE_LEVEL.H3}
                        className={styles.approcheRow}
                    >
                        <p>
                            Ce découpage fin permet de traiter un sujet en
                            profondeur et de ne pas rester sur des généralités.
                        </p>
                    </FeatureRow.Component>
                    <FeatureRow.Component
                        svgName="chooseYourPath"
                        imagePosition={FeatureRow.ImagePosition.IMAGE_LEFT}
                        title={
                            <React.Fragment>
                                Tu choisis les capsules
                                <br />
                                <strong>dont tu as besoin</strong> à l'instant T
                            </React.Fragment>
                        }
                        titleLevel={FEATURE_ROW_TITLE_LEVEL.H3}
                        className={styles.approcheRow}
                    >
                        <React.Fragment>
                            <p>
                                Ici, pas de parcours imposé, et pour 2 raisons:
                            </p>
                            <ul>
                                <li>
                                    tu es le mieux placé pour savoir ce dont tu
                                    as besoin
                                </li>
                                <li>
                                    c’est en allant chercher l’information que
                                    tu consolides petit à petit tes acquis.
                                </li>
                            </ul>
                        </React.Fragment>
                    </FeatureRow.Component>
                    <FeatureRow.Component
                        imagePosition={FeatureRow.ImagePosition.IMAGE_RIGHT}
                        svgName="simpleAndEffective"
                        title={
                            <React.Fragment>
                                Une capsule c'est du texte,
                                <br />
                                des schémas et des démos.
                                <br />
                                C'est <strong>simple et efficace !</strong>
                            </React.Fragment>
                        }
                        titleLevel={FEATURE_ROW_TITLE_LEVEL.H3}
                        className={styles.approcheRow}
                    >
                        <ul>
                            <li>
                                on lit beaucoup plus rapidement (250
                                mots/minute) qu’on ne regarde une vidéo (150
                                mots/minute)
                            </li>
                            <li>
                                les capsules intègrent des schémas sur-mesure
                                pour représenter chaque étape et chaque concept
                            </li>
                            <li>
                                les démos courtes mais ciblées illustrent en
                                quelques secondes les explications données.
                                C’est la preuve par l’exemple.
                            </li>
                        </ul>
                    </FeatureRow.Component>
                </div>
            </section>
            <section className={cx(styles.section, styles.sectionWhite)}>
                <Container>
                    <h2 className={styles.sectionTitle}>
                        A qui s’adressent
                        <br /> nos capsules ?
                    </h2>
                    <MainFeatures.Component
                        theme={MainFeatures.Theme.WHITE}
                        titleSvgSides={MainFeatures.SvgSides.NONE}
                        content={[
                            {
                                svgName: 'devNewbee',
                                title: 'Le dev newbee',
                                description:
                                    "qui a besoin d'explications claires et de schémas pour comprendre vite et mémoriser durablement",
                            },
                            {
                                svgName: 'devIntermediate',
                                title: 'Le dev expérimenté',
                                description:
                                    "qui a besoin d'aller plus loin que les fondamentaux et d'analyser les sujets en profondeur",
                            },
                            {
                                svgName: 'devExpert',
                                title: 'Le dev expert',
                                description:
                                    'qui a besoin de retrouver rapidement les réponses à ses questions',
                                scale: 1,
                            },
                        ]}
                    />
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionBlack)}>
                <Container
                    className={cx(styles.centerContainer, styles.textCenter)}
                >
                    <h2 className={cx(styles.sectionTitle, styles.textYellow)}>
                        Comment y accéder ?
                    </h2>
                    <p className={cx(styles.subtitle, styles.textWhite)}>
                        Toutes les capsules sont centralisées sur notre
                        plateforme
                    </p>
                    <Button.Component
                        theme={Button.Theme.ORANGE}
                        type={Button.Type.ROUTER_LINK_AS_BUTTON}
                        to="/git"
                        className={styles.platformBtn}
                    >
                        Retrouve dès maintenant nos capsules de formation sur
                        Git
                    </Button.Component>
                    <div className={styles.list}>
                        <div className={styles.row}>
                            <div className={styles.rowTitleContainer}>
                                <span className={styles.rowFigure}>1</span>
                                <p
                                    className={cx(
                                        styles.rowTitle,
                                        styles.textYellow
                                    )}
                                >
                                    Explore tes capsules
                                </p>
                            </div>
                            <div>
                                <CustomVideoPlayer
                                    vimeoVideoId="480236802"
                                    title="Explore tes capsules"
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowTitleContainer}>
                                <span className={styles.rowFigure}>2</span>
                                <p
                                    className={cx(
                                        styles.rowTitle,
                                        styles.textYellow
                                    )}
                                >
                                    Utilise les filtres
                                </p>
                            </div>
                            <CustomVideoPlayer
                                vimeoVideoId="480237383"
                                title="Utilise les filtres"
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowTitleContainer}>
                                <span className={styles.rowFigure}>3</span>
                                <p
                                    className={cx(
                                        styles.rowTitle,
                                        styles.textYellow
                                    )}
                                >
                                    Teste tes nouveaux acquis
                                </p>
                            </div>
                            <CustomVideoPlayer
                                vimeoVideoId="480237307"
                                title="Teste tes nouveaux acquis"
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowTitleContainer}>
                                <span className={styles.rowFigure}>4</span>
                                <p
                                    className={cx(
                                        styles.rowTitle,
                                        styles.textYellow
                                    )}
                                >
                                    Identifie les capsules à retravailler
                                </p>
                            </div>
                            <CustomVideoPlayer
                                vimeoVideoId="480236659"
                                title="Identifie les capsules à retravailler"
                            />
                        </div>
                        <div className={styles.row}>
                            <div className={styles.rowTitleContainer}>
                                <span className={styles.rowFigure}>5</span>
                                <p
                                    className={cx(
                                        styles.rowTitle,
                                        styles.textYellow
                                    )}
                                >
                                    Achète de nouvelles capsules via la boutique
                                    intégrée
                                </p>
                            </div>
                            <CustomVideoPlayer
                                vimeoVideoId="480236700"
                                title="Achète de nouvelles capsules via la boutique intégrée"
                            />
                        </div>
                    </div>
                </Container>
            </section>
            <section className={cx(styles.section, styles.sectionCourseBanner)}>
                <Container>
                    <div
                        className={cx(
                            styles.centerContainer,
                            styles.card,
                            'yellowSelection'
                        )}
                    >
                        <CourseBanner
                            forceColor="forceOrange"
                            svgName="gitMonogram"
                            title={[
                                `Découvre notre première formation sur Git`,
                            ]}
                            description={
                                'Git est devenu incontournable. Le maîtriser est un super pouvoir. \nAvec ces capsules tu sauras entre autres:'
                            }
                            content={[
                                'Te synchroniser avec un projet git distant',
                                'Maîtriser et réécrire ton historique de commits',
                                'Collaborer sur des projets complexes avec ton équipe',
                                'Modifier, annuler des commits',
                                'Fusionner ou éclater des commits',
                                'Annuler une fusion ou un rebase',
                                'Désindexer sans stress tes fichiers',
                                'Créer, manipuler, synchroniser, fusionner et réecrire des branches',
                                'Retrouver les commits que tu pensais perdus',
                                "Rechercher et filtrer dans l'historique",
                                'Remiser ton travail',
                                'Customiser ton affichage',
                                'Analyser l’historique, visualiser les écarts, retrouver l’origine d’un bug et son auteur',
                                'Comprendre les retours console de git',
                                'Configurer git correctement',
                            ]}
                            btnTheme={Button.Theme.ORANGE}
                            btnLinkUrl="/git"
                        />
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default Concept;
