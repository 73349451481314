import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './CheckMiniCard.module.scss';

const CheckMiniCard = ({ forceColor, title }) => {
    return (
        <div key={title} className={cx(styles.container)}>
            <SvgComponent
                name="check"
                className={cx(forceColor, styles.icon)}
            />
            <p className={styles.title}>{title}</p>
        </div>
    );
};

CheckMiniCard.propTypes = {
    forceColor: PropTypes.string,
    title: PropTypes.string,
};

export default CheckMiniCard;
