import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './FeatureRow.module.scss';

export const FEATURE_ROW_IMAGE_POSITION = {
    IMAGE_LEFT: 'imageLeft',
    IMAGE_RIGHT: 'imageRight',
};

export const TITLE_LEVEL = {
    H2: 'h2',
    H3: 'h3',
};

const FeatureRow = ({
    imagePosition = FEATURE_ROW_IMAGE_POSITION.IMAGE_LEFT,
    svgName,
    title = [],
    children,
    accentColor = '',
    illustrationJSX,
    titleLevel = TITLE_LEVEL.H2,
    titleClassName,
    ...rest
}) => {
    return (
        <article {...rest}>
            <div
                className={cx(
                    styles.container,
                    imagePosition === FEATURE_ROW_IMAGE_POSITION.IMAGE_LEFT
                        ? styles.imageLeft
                        : styles.imageRight
                )}
            >
                {imagePosition === FEATURE_ROW_IMAGE_POSITION.IMAGE_LEFT && (
                    <div className={styles.illustration}>
                        {illustrationJSX ? (
                            illustrationJSX
                        ) : (
                            <SvgComponent
                                name={svgName}
                                className={styles.svg}
                            />
                        )}
                    </div>
                )}

                <div className={styles.content}>
                    {
                        {
                            [TITLE_LEVEL.H2]: (
                                <h2
                                    className={cx(
                                        styles.title,
                                        styles[accentColor],
                                        titleClassName
                                    )}
                                >
                                    {Array.isArray(title)
                                        ? title.join('\n')
                                        : title}
                                </h2>
                            ),
                            [TITLE_LEVEL.H3]: (
                                <h3
                                    className={cx(
                                        styles.title,
                                        styles[accentColor],
                                        titleClassName
                                    )}
                                >
                                    {Array.isArray(title)
                                        ? title.join('\n')
                                        : title}
                                </h3>
                            ),
                        }[titleLevel]
                    }
                    {children}
                </div>

                {imagePosition === FEATURE_ROW_IMAGE_POSITION.IMAGE_RIGHT && (
                    <div className={styles.illustration}>
                        {illustrationJSX ? (
                            illustrationJSX
                        ) : (
                            <SvgComponent
                                name={svgName}
                                className={styles.svg}
                            />
                        )}
                    </div>
                )}
            </div>
        </article>
    );
};

FeatureRow.propTypes = {
    imagePosition: PropTypes.oneOf(Object.values(FEATURE_ROW_IMAGE_POSITION)),
    svgName: PropTypes.string,
    title: PropTypes.any,
    children: PropTypes.node,
    accentColor: PropTypes.string,
};

export default {
    Component: FeatureRow,
    ImagePosition: FEATURE_ROW_IMAGE_POSITION,
};
