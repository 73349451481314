import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './MainFeatures.module.scss';

function MainFeatures(props) {
    return (
        <div className={cx(styles.container, styles[props.theme])}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    {(props.titleSvgSides === 'both' ||
                        props.titleSvgSides === 'left') && (
                        <SvgComponent name={props.titleSvgName} />
                    )}

                    <h2 className={styles.title}>{props.title.join('\n')}</h2>
                    {(props.titleSvgSides === 'both' ||
                        props.titleSvgSides === 'right') && (
                        <SvgComponent name={props.titleSvgName} />
                    )}
                </div>
                {props.description && (
                    <p className={styles.description}>
                        {props.description.split('\\n').map((str, index) => (
                            <React.Fragment key={index}>
                                {str}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                )}
            </div>
            <div className={styles.grid}>
                {props.content.map(item => {
                    return (
                        <div key={item.title} className={styles.cell}>
                            <div className={styles.svgContainer}>
                                <SvgComponent
                                    name={item.svgName}
                                    className={styles.svg}
                                />
                            </div>
                            <h3 className={styles.cellTitle}>{item.title}</h3>
                            <p className={styles.cellDescription}>
                                {item.description}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const THEME = {
    WHITE: 'themeWhite',
    BLACK: 'themeBlack',
};

const SVG_SIDES = {
    NONE: 'none',
    BOTH: 'both',
    LEFT: 'left',
    RIGHT: 'right',
};

MainFeatures.defaultProps = {
    theme: THEME.WHITE,
    title: [],
    titleSvgSides: SVG_SIDES.NONE,
    titleSvgName: '',
    content: [],
};

MainFeatures.propTypes = {
    theme: PropTypes.oneOf(Object.values(THEME)),
    title: PropTypes.arrayOf(PropTypes.string),
    titleSvgSides: PropTypes.oneOf(Object.values(SVG_SIDES)),
    titleSvgName: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            svgName: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        })
    ),
};

export default {
    Component: MainFeatures,
    Theme: THEME,
    SvgSides: SVG_SIDES,
};
