import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/Seo';
import Theme from '../components/Theme/Theme';
import Concept from '../components/Concept/Concept';

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Theme accentKey="home">
            <Concept />
        </Theme>
    </Layout>
);

export default IndexPage;
